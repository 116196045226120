// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap icons
@import "~bootstrap-icons/font/bootstrap-icons";

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';
